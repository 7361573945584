import DesCov from "../assets/DesignCov.png";
import DevCov from "../assets/DevCov.png";
import InterCov from "../assets/InterCov.png";
import trdCov from "../assets/3dCov.png";

import { Card, CardContent } from "./ui/card";
import { FlipWords } from "./ui/flip-words";
import { HoverBorderGradient } from "./ui/hover-border-gradient";
import { Separator } from "@radix-ui/react-dropdown-menu";
import "@lottiefiles/lottie-player";
import { LeftWing, RightWing } from "./Icons";
import { motion } from "framer-motion";

// Text content constants
const words = [
  "Startup.",
  "Idea.",
  "Project.",
  "Solution.",
  "Agency.",
  "Developer.",
];

const services = [
  {
    image: DesCov,
    title: "Design",
    description: "Design modern and effective brand identities and websites.",
  },
  {
    image: DevCov,
    title: "Development",
    description:
      "Develop web and mobile solutions that achieve your business needs.",
  },
  {
    image: InterCov,
    title: "Interaction",
    description: "Enhance user engagement through interactive experiences.",
  },
  {
    image: trdCov,
    title: "3D Experience",
    description:
      "Create immersive 3D experiences that make you stand out from others.",
  },
];

export const Intro = () => {
  return (
    <section
      id="services"
      className="container md:h-[700px] lg:h-[800px] h-fit text-center my-4 mb-8"
    >
      <header className="text-center pt-4 sm:pt-24  md:pt-24 group">
        <div>
          <div className="flex mx-auto justify-center items-center w-fit">
            <div className="group-hover:-rotate-6 transition duration-500">
              <LeftWing />
            </div>
            <div className="text-center text-lg md:text-2xl lg:text-4xl mx-auto font-normal text-dark dark:text-white">
              The perfect start for any
              <br />
              <FlipWords
                className="font-bold dark:text-zinc-50 text-zinc-900 mt-2"
                words={words}
              />
            </div>
            <div className="group-hover:rotate-6 transition duration-500">
              <RightWing />
            </div>
          </div>
        </div>
      </header>

      {/* --- Mobile view service--- */}
      <div className="Block lg:hidden grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 justify-center gap-6 mt-16">
        {services.map((service, index) => (
          <Card
            key={index}
            className="md:w-full w-fit rounded-full min-w-40 border-4 h-fit border-muted transition-all group hover:rounded-3xl bg-black duration-500"
          >
            <CardContent className="grid grid-cols-2 justify-center gap-6 p-4">
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-full object-contain rounded-full group-hover:rounded-3xl grayscale group-hover:filter-none"
              />
              <div className="w-full h-full py-2">
                <h1 className="text-left font-semibold text-zinc-300">
                  {service.title}
                </h1>
                <p className="text-left text-neutral-500 my-2 text-sm">
                  {service.description}
                </p>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* --- Web view service--- */}
      <div className="hidden lg:grid grid-cols-4 justify-center gap-6 mt-24">
        {services.map((service, index) => (
          <Card
            key={index}
            className="md:w-full w-fit bg-muted/50 rounded-full min-w-40 border-4 h-fit border-muted transition-all group hover:rounded-3xl hover:bg-black duration-500"
          >
            <CardContent>
              <img
                src={service.image}
                alt={service.title}
                className="w-full object-contain rounded-full group-hover:rounded-3xl mt-10 grayscale group-hover:filter-none"
              />
              <h1 className="hidden group-hover:block text-lg font-semibold my-8 text-muted-foreground">
                {service.title}
              </h1>
              <p className="hidden group-hover:block text-neutral-500 max-w-lg mx-auto my-8 text-sm text-center relative">
                {service.description}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};
