import { ContainerScroll } from "./ui/container-scroll-animation";
import Prj1 from "../assets/images/Projects/Upcoming_project_1.png";
import Prj2 from "../assets/images/Projects/Upcoming_project_2.png";
import Prj3 from "../assets/images/Projects/Upcoming_project_3.png";
import Prj4 from "../assets/images/Projects/Upcoming_project_4.png";

import BigImg from "../assets/images/Projects/Listiny.png";

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { MedalIcon, MapIcon, PlaneIcon, GiftIcon } from "../components/Icons";
import { Badge } from "./ui/badge";

export function HeroScrollDemo() {
  return (
    <section
      className="bg-gradient-to-b from-muted/50 to-transparent "
      id="products"
    >
      <div className=" container flex flex-col  overflow-hidden h-fit  ">
        <ContainerScroll
          titleComponent={
            <>
              <h1 className="sm:text-4xl my-8 mx-12 md:text-2xl font-regular text-black dark:text-white  ">
                be part of our journey <br />
                <span className="text-4xl md:text-[3rem] font-semibold mt-1 leading-none">
                  launche your project faster
                </span>
              </h1>
            </>
          }
        >
          <img
            width={500}
            height={500}
            src={BigImg}
            alt="Elaerning Platform"
            className="w-full  h-full object-contain rounded-lg "
          />
        </ContainerScroll>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          <Card className="rounded-2xl hover:bg-muted/50 border-0  ">
            <CardContent>
              <img
                src={Prj1}
                alt=""
                className="border-2 border-muted-foreground w-full object-contain rounded-3xl mt-5"
              />

              <div className="flex flex-wrap  justify-between mt-4">
                <h2 className="text-2xl my-4 text-left relative ">sahlla</h2>
                <Badge variant="default" className="text-sm  w-fit h-fit my-5">
                  <a href="https://sahlla.listoit.com/" target="blank">
                    {" "}
                    Discover
                  </a>
                </Badge>
              </div>
            </CardContent>
          </Card>
          <Card className="rounded-2xl hover:bg-muted/50 border-0  ">
            <CardContent>
              <img
                src={Prj2}
                alt=""
                className="border-2 border-muted-foreground w-full object-contain rounded-3xl mt-5"
              />

              <div className="flex flex-wrap  justify-between mt-4">
                <h2 className="text-2xl my-4 text-left relative ">
                  Oceantrip{" "}
                </h2>
                <Badge
                  variant="secondary"
                  className="text-sm  w-fit h-fit my-5"
                >
                  coming soon
                </Badge>
              </div>
            </CardContent>
          </Card>

          <Card className="rounded-2xl hover:bg-muted/50 border-0  ">
            <CardContent>
              <img
                src={Prj3}
                alt=""
                className="border-2 border-muted-foreground w-full object-contain rounded-3xl mt-5"
              />

              <div className="flex flex-wrap  justify-between mt-4">
                <h2 className="text-2xl my-4 text-left relative ">Dealme</h2>
                <Badge
                  variant="secondary"
                  className="text-sm  w-fit h-fit my-5"
                >
                  coming soon
                </Badge>
              </div>
            </CardContent>
          </Card>

          <Card className="rounded-2xl  border-0  ">
            <CardContent>
              <img
                src={Prj4}
                alt=""
                className="border-2 border-muted-foreground w-full object-contain rounded-3xl mt-5"
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  );
}
