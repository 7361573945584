import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { LogoIcon } from "./Icons";

export const Splashscreen = () => {
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 1500); // 1.5 second delay

    return () => clearTimeout(timer);
  }, []);

  return (
    <section
      className={cn(
        "fixed z-[5000] transition-transform duration-1000 top-0 h-[100dvh] w-full bg-background",
        {
          "-translate-y-[110%] ": startAnimation,
        }
      )}
    >
      <div className="flex h-screen fade-in-0 duration-1000 animate-in w-screen justify-center items-center">
        <div className="shine-effect">
          <LogoIcon />
        </div>
      </div>
    </section>
  );
};
