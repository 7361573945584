import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface FAQProps {
  question: string;
  answer: string;
  value: string;
}

const FAQList: FAQProps[] = [
  {
    question: "Who runs the Listoit studio?",
    answer:
      "Listoit was created by S.walid & T.abderahmane and S.mounir , including others Designers and software engineers to help startups ,  We also build products Ourselves ..",
    value: "item-1",
  },
  {
    question: "How do we communicate and manage the project?",
    answer:
      "Listoit uses Clickup for project management, ensuring seamless communication and collaboration..",
    value: "item-2",
  },
  {
    question: "How quickly will I get some work done?",
    answer:
      "Expect the initial concept within 2-3 days, and LISTOIT will make revisions until it meets your complete satisfaction. For bigger projects it can take up to 4 weeks.",
    value: "item-3",
  },
  {
    question: "Why do you only have two spots ?",
    answer:
      "LISTOIT believes in providing the best possible service to clients, and to ensure that, LISOTIT has decided to limit the workload to just two projects at a time. This approach allows LISOTIT to dedicate it's full attention and expertise to each project, ensuring quality and timely delivery.",
    value: "item-4",
  },
  {
    question: "What if i don't like the result?",
    answer:
      "Our team wants you to be happy with the results! Hence the unlimited requests.",
    value: "item-5",
  },
  {
    question: "Are there any refunds?",
    answer:
      "Unfortunately, not. Because of the intense creative demands for delivering top-notch design and development within tight timeframes and the thorough research invested to achieve the best outcomes, LISTOIT cannot provide our services without compensation.",
    value: "item-5",
  },
];

export const FAQ = () => {
  return (
    <section id="FAQ" className=" pb-24  md:py-0 ">
      <h1 className=" font-regular text-black dark:text-white  ">
        <span className="text-4xl md:text-4xl font-semibold mt-3 leading-none">
          Answers for FAQ
        </span>
        <br />
        <p className="text-opacity-80 pb-16">
          Can’t find the answer you’re looking for?{" "}
          <a
            className="font-bold"
            href="mailto:listoit.info@gmail.com
"
          >
            Reach out to us{" "}
          </a>
          and we will get in touch with you
        </p>
      </h1>

      <Accordion type="single" collapsible className="w-full AccordionRoot">
        {FAQList.map(({ question, answer, value }: FAQProps) => (
          <AccordionItem key={value} value={value}>
            <AccordionTrigger className="text-left">
              {question}
            </AccordionTrigger>

            <AccordionContent>{answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};
