import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { Menu } from "lucide-react";
import { useEffect, useState } from "react";
import { LogoIcon } from "./Icons";
import { ModeToggle } from "./mode-toggle";
import { Button, buttonVariants } from "./ui/button";
import { FloatingNav } from "./ui/floating-navbar";
import { openTawkChat } from "@/lib/utils";

interface RouteProps {
  href: string;
  label: string;
}

const routeList: RouteProps[] = [
  {
    href: "#services",
    label: "Services",
  },
  {
    href: "#products",
    label: "Products",
  },
  {
    href: "https://templates.listoit.com/",
    label: "Templates",
  },
];

const routeList2: RouteProps[] = [
  {
    href: "#tools",
    label: "Tools",
  },
  {
    href: "#FAQ",
    label: "FAQ",
  },
];

export const NavbarMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [showNavbar, setShowNavbar] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.scrollY;
      if (top > 0 && !isSticky) {
        setIsSticky(true);
        setShowNavbar(true); // Show navbar when scrolling up
      } else if (top === 0) {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    return () => {
      clearTimeout(timeout);
    };
  }, [isSticky]);

  return (
    <header
      className={`sticky top-0 z-50 mt-3 w-full bg-white dark:border-b-slate-700 dark:bg-background ${
        isSticky ? "animate-navbar-show" : ""
      } ${showNavbar ? "" : "hidden"}`}
    >
      <style>{`
        .nav-link {
          position: relative;
          text-decoration: none; /* Remove default underline */
          color: inherit; /* Inherit text color */
        }

        .nav-link::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px; /* Height of the underline */
          bottom: 0;
          left: 0;
          background-color: currentColor; /* Color of the underline */
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }

        .nav-link:hover::before {
          visibility: visible;
          transform: scaleX(1);
        }

        @keyframes navbar-show {
          0% {
            transform: translateY(-100%);
          }
          100% {
            transform: translateY(0);
          }
        }

        .animate-navbar-show {
          animation: navbar-show 0.5s ease-in-out forwards;
        }
      `}</style>

      <NavigationMenu className="md:mx-auto hidden md:block">
        <NavigationMenuList className="container h-14 md:w-screen flex justify-between">
          {/* desktop */}
          <NavigationMenuItem className="w-full font-bold flex justify-between items-center">
            {/* right items */}
            <nav className="hidden md:flex gap-2">
              {routeList.map((route: RouteProps, i) => (
                <a
                  rel="noreferrer noopener"
                  href={route.href}
                  key={i}
                  className={`text-[17px] ${buttonVariants({
                    variant: null,
                  })} nav-link`}
                >
                  {route.label}
                </a>
              ))}
            </nav>

            {/* Logo icon */}
            <div className="hidden md:flex gap-2">
              <a
                rel="noreferrer noopener"
                href="/"
                className="ml-2 font-bold text-xl flex"
              >
                <LogoIcon />
              </a>
            </div>

            {/* desktop left items */}
            <div className="hidden md:flex gap-2">
              <nav className="hidden md:flex gap-2">
                {routeList2.map((route: RouteProps, i) => (
                  <a
                    rel="noreferrer noopener"
                    href={route.href}
                    key={i}
                    className={`text-[17px] ${buttonVariants({
                      variant: null,
                    })} nav-link`}
                  >
                    {route.label}
                  </a>
                ))}
              </nav>

              <Button onClick={openTawkChat} className="rounded-full border">
                Let's talk
              </Button>

              <ModeToggle />
            </div>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
      {/* mobile */}

      <FloatingNav className="w-full  md:hidden " />
    </header>
  );
};
