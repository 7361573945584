"use client";
import React, { useState } from "react";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";
import { cn, openTawkChat } from "@/lib/utils";
import { LogoIcon } from "../Icons";
import { ModeToggle } from "../mode-toggle";
import { Button } from "./button";

export const FloatingNav = ({
  navItems,
  className,
}: {
  navItems?: {
    name: string;
    link: string;
    icon?: JSX.Element;
  }[];
  className?: string;
}) => {
  const { scrollYProgress } = useScroll();
  const [visible, setVisible] = useState(true);

  useMotionValueEvent(scrollYProgress, "change", (current) => {
    // Check if current is not undefined and is a number
    if (typeof current === "number") {
      let direction = current - scrollYProgress.getPrevious();

      // If at the top of the page, always make the navbar visible
      if (scrollYProgress.get() === 0) {
        setVisible(true);
      } else {
        if (direction < 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    }
  });

  return (
    <AnimatePresence mode="wait">
      <div className=" container grid md:grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{
            opacity: 1,
            y: -100,
          }}
          animate={{
            y: visible ? 0 : -100,
            opacity: visible ? 1 : 0,
          }}
          transition={{
            duration: 0.2,
          }}
          className={cn(
            "flex max-w-fit fixed top-5 inset-x-0 mx-auto border text-white border-white/[0.2] rounded-full bg-black  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] z-[5000] pr-2 pl-4 py-2 items-center justify-center space-x-4",
            className
          )}
        >
          <ModeToggle />

          <a href="#services">Services</a>
          <a href="https://templates.listoit.com/">Templates</a>

          <Button
            onClick={openTawkChat}
            className="border  text-sm font-medium relative border-neutral-200 border-white/[0.2] bg-white text-black px-4 py-2 rounded-full"
          >
            <span>Let's talk</span>
            <span className="absolute inset-x-0 w-1/2 mx-auto -bottom-px bg-gradient-to-r from-transparent via-zinc-500 to-transparent h-px" />
          </Button>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
