import { Badge } from "./ui/badge";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import image from "../assets/templates/Waymlinker.png";
import image2 from "../assets/templates/Homehive.png";
import image3 from "../assets/templates/enji Dev.png";
import image4 from "../assets/templates/Nutritrack.png";
import smartwave from "../assets/templates/SmartWaver_Cov.png";
import Lumen from "../assets/templates/Lumen.png";
import Corporation from "../assets/templates/Corporation.png";
import workshop from "../assets/templates/workshop.png";

import { Button } from "./ui/button";
import { ChevronDown, ChevronRight } from "lucide-react";

interface FeatureProps {
  title: string;
  description: string;
  image: string;
}

const features: FeatureProps[] = [
  {
    title: "Responsive Design",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nesciunt est nostrum omnis ab sapiente.",
    image: image4,
  },
  {
    title: "Intuitive user interface",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nesciunt est nostrum omnis ab sapiente.",
    image: image3,
  },
  {
    title: "AI-Powered insights",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nesciunt est nostrum omnis ab sapiente.",
    image: image,
  },
];

const featureList: string[] = [
  "Dark/Light theme",
  "Reviews",
  "Features",
  "Pricing",
  "Contact form",
  "Our team",
  "Responsive design",
  "Newsletter",
  "Minimalist",
];

export const RecentTempalte = () => {
  return (
    <section id="templates" className="container py-24 sm:py-32 space-y-8">
      <div className="flex   justify-between items-center">
        <h2 className="text-3xl lg:text-3xl  md:text-left">Recent Templates</h2>
        <Button
          variant="ghost"
          className="hidden sm:block text-muted-foreground rounded-full "
        >
          <a href="https://templates.listoit.com/">{"show all"}</a>
        </Button>
        <Button variant="secondary" className="block sm:hidden rounded-full ">
          <a href="https://templates.listoit.com/">
            <ChevronRight className="h-4 w-4 " />
          </a>
        </Button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:gap-4 gap-0">
        <a href="https://templates.listoit.com/smartwave-product-landing-page">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={smartwave}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                SmartWave{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                A Unique Product landing page designed for Ecommerce and Stores.{" "}
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Landing page{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Product
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Minimalist
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        <a href="https://templates.listoit.com/waylinker-ride-sharing-app">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={image}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                Waylinker
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                Waylinker is a comprehensive app tailored for both drivers and
                riders .
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  UI UX Design
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Mobile APP
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Figma{" "}
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        <a href="https://templates.listoit.com/astro-nutrition-track-landing-page">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={image4}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                NutriTrack{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                A simple landing page theme designed for Notion creators{" "}
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Landing page{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Responsive design
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Minimalist
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        <a href="https://templates.listoit.com/homehive-real-estate-app">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={image2}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                Homehive{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                Your App to find dream home or ideal investment property.
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Figma{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  UI UX Design{" "}
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Minimalist
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        {/*
         <a href="https://templates.listoit.com/enji-dev-portfolio">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={image3}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                Enjidev Portfolio{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                A personal website with a blog, project showcase, and work
                information.
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Portfolio{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Responsive design
                </Badge>

                <Badge variant="outline" className="text-sm">
                  Blog{" "}
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        <a href="https://templates.listoit.com/enterprise-product-landing-page">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={Corporation}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                Corporio{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                Enterprise level corporation website template .{" "}
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Ecommerce{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Product Page
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Html
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        <a href="https://templates.listoit.com/gatsby-lumen-starter-blog">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={Lumen}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                Lumen{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                A constantly evolving and thoughtful architecture for creating
                static blogs.{" "}
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Blog{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Gatsby
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Reactjs
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        <a href="https://templates.listoit.com/photo-grahy-workshop-landing-page">
          <Card className="rounded-2xl hover:bg-muted/50 border-0 ">
            <CardContent>
              <img
                src={workshop}
                alt=""
                className="border-2 border-white w-full object-contain rounded-lg mt-5"
              />

              <h2 className="text-2xl mx-auto my-4 text-left relative ">
                WoodsWorkshop{" "}
              </h2>
              <p className="text-neutral-500 max-w-lg mx-auto  text-sm text-left relative z-10">
                Photography workshop in beautiful Gallery Website with a nice
                Layout .{" "}
              </p>

              <div className="flex flex-wrap md:justify-right gap-2 space-y-0 my-4">
                <Badge variant="outline" className="text-sm  ">
                  Photography{" "}
                </Badge>
                <Badge variant="outline" className="text-sm ">
                  Gatsby
                </Badge>
                <Badge variant="outline" className="text-sm">
                  Reactjs
                </Badge>
              </div>
            </CardContent>
          </Card>
        </a>

        */}
      </div>
    </section>
  );
};
