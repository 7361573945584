import { cn } from "@/lib/utils";
import { motion, useAnimation } from "framer-motion";
import { Badge, ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import pict2 from "../assets/images/LeftCTA.png";
import pict1 from "../assets/images/RightCTA.png";
import { Spotlight } from "./ui/Spotlight";
import { Button } from "./ui/button";
import { HoverBorderGradient } from "./ui/hover-border-gradient";

export function SpotlightHero() {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        // Adjust the scroll trigger point as needed
        setAnimate(false);
      } else {
        setAnimate(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="Hero" className="container md:mt-0 mt-24">
      <div className=" md:h-ful  dark:border-2  rounded-3xl mt-4 md:h-[50rem] h-content pb-9 w-full flex md:items-center md:justify-center dark:bg-zinc-950 bg-black/[0.96]  relative overflow-hidden">
        <BackgroundCellCore />
        <Spotlight
          className="z-40 -top-40 left-0 md:left-60 md:-top-20 hidden md:block"
          fill="white"
        />

        <div className="z-40 p-4 max-w-6xl  mx-auto     w-full pt-20 md:pt-20 ">
          <motion.h1
            className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1.5, ease: "easeOut" }}
          >
            Accelerate your <br /> startup growth.
          </motion.h1>
          <motion.p
            className="mt-4 font-light text-base text-neutral-500 max-w-lg text-center mx-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 2, ease: "easeOut" }}
          >
            We empower startups & Agencies to deliver high-quality work faster
            and without growing your dev budget.
          </motion.p>

          <div className="lg:mt-20 mt-10 flex justify-center text-center">
            <HoverBorderGradient
              containerClassName="rounded-full"
              as="button"
              className="dark:bg-black bg-black text-white dark:text-white flex items-center"
            >
              <p className=" mr-2 "> 2 Spots available</p>
              <div className="mx-1 h-2 w-2 rounded-full bg-white border-2 border-white " />{" "}
              <div className="mx-1 h-2 w-2 rounded-full border-white border-2 "></div>
              <div className="mx-1 h-2 w-2 rounded-full border-white border-2 "></div>
            </HoverBorderGradient>

            <Button
              variant="outline"
              size="icon"
              className="absolute hidden min-[1200px]:flex bottom-10 mt-20 text-center rounded-full animate-bounce"
            >
              <a href="#target">
                <ChevronDown className="h-4 w-4 " />
              </a>
            </Button>

            <motion.img
              width={500}
              height={500}
              src={pict1}
              alt="linear demo image"
              className="hidden md:block absolute -right-0 lg:-right-14 md:-right-24 grayscale filter -bottom-8 object-contain"
              initial={{ y: 500 }} // Initial position (off the screen)
              animate={animate ? { y: 0 } : { y: 500 }} // Final position (on the screen)
              transition={{ delay: 1, duration: 1.5 }} // Delay of 1s and duration of 1.5s
            />
            <motion.img
              width={500}
              height={500}
              src={pict2}
              alt="linear demo image"
              className="hidden md:block absolute -left-0 lg:-left-0 md:-left-20 grayscale filter -bottom-0 object-contain"
              initial={{ y: 500 }} // Initial position (off the screen)
              animate={animate ? { y: 0 } : { y: 500 }} // Final position (on the screen)
              transition={{ delay: 1, duration: 1.5 }} // Delay of 1s and duration of 1.5s
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const BackgroundCellCore = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const ref = useRef<any>(null);

  const handleMouseMove = (event: any) => {
    const rect = ref.current && ref.current.getBoundingClientRect();
    setMousePosition({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });
  };

  const size = 700;
  return (
    <div
      ref={ref}
      onMouseMove={handleMouseMove}
      className="h-full absolute inset-0"
    >
      <div className="absolute h-full inset-y-0  overflow-hidden">
        <div className="absolute h-full w-full pointer-events-none -bottom-2 z-40 bg-black [mask-image:linear-gradient(to_bottom,transparent,black)]"></div>
        <div
          className="absolute inset-0 z-20 bg-transparent"
          style={{
            maskImage: `radial-gradient(
              ${size / 4}px circle at center,
             white, transparent
            )`,
            WebkitMaskImage: `radial-gradient(
            ${size / 4}px circle at center,
            white, transparent
          )`,
            WebkitMaskPosition: `${mousePosition.x - size / 2}px ${
              mousePosition.y - size / 2
            }px`,
            WebkitMaskSize: `${size}px`,
            maskSize: `${size}px`,
            pointerEvents: "none",
            maskRepeat: "no-repeat",
            WebkitMaskRepeat: "no-repeat",
          }}
        >
          <Pattern cellClassName="border-white-600 relative z-[100]" />
        </div>
        <Pattern className="opacity-[0.5]" cellClassName="border-neutral-700" />
      </div>
    </div>
  );
};

const Pattern = ({
  className,
  cellClassName,
}: {
  className?: string;
  cellClassName?: string;
}) => {
  const x = new Array(47).fill(0);
  const y = new Array(30).fill(0);
  const matrix = x.map((_, i) => y.map((_, j) => [i, j]));
  const [clickedCell, setClickedCell] = useState<any>(null);

  return (
    <div className={cn("flex flex-row  relative z-30", className)}>
      {matrix.map((row, rowIdx) => (
        <div
          key={`matrix-row-${rowIdx}`}
          className="flex flex-col  relative z-20 border-b"
        >
          {row.map((column, colIdx) => {
            const controls = useAnimation();

            useEffect(() => {
              if (clickedCell) {
                const distance = Math.sqrt(
                  Math.pow(clickedCell[0] - rowIdx, 2) +
                    Math.pow(clickedCell[1] - colIdx, 2)
                );
                controls.start({
                  opacity: [0, 1 - distance * 0.1, 0],
                  transition: { duration: distance * 0.2 },
                });
              }
            }, [clickedCell]);

            return (
              <div
                key={`matrix-col-${colIdx}`}
                className={cn(
                  "bg-transparent border-l border-b border-neutral-600",
                  cellClassName
                )}
                onClick={() => setClickedCell([rowIdx, colIdx])}
              >
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileHover={{
                    opacity: [0, 1, 0.5],
                  }}
                  transition={{
                    duration: 0.5,
                    ease: "backOut",
                  }}
                  animate={controls}
                  className="bg-[rgba(255,255,255,0.3)] h-12 w-12" //  rgba(14, 165, 233, 0.15) for a more subtle effect
                ></motion.div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
