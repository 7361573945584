import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { toast } from "sonner";
import { Button } from "./ui/button";
import { openTawkChat } from "@/lib/utils";

export const OurTarget = () => {
  function buttonVariants(arg0: { variant: string }) {
    throw new Error("Function not implemented.");
  }

  return (
    <section id="target" className=" bg-muted/50 py-10 sm:py-10 space-y-8">
      <h2 className="text-3xl lg:text-4xl  md:text-left container mt-10">
        Who benefits from our Services
      </h2>

      <div className=" container py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-8  ">
        <Card className="sm:p-5 p-2  bg-black text-white dark:bg-white dark:text-black rounded-3xl border-4  border-muted  ">
          <CardHeader>
            <CardTitle>Early Stage Startups</CardTitle>
          </CardHeader>

          <CardContent className="sm:me-20 md:me-40 text-muted-foreground">
            Whether you need to bring your MVP to life, develop your POC, or
            refine an existing product, we're here to assist! Our template
            designs set launch records and prove their usability. Work smarter
            and faster.
          </CardContent>

          <CardFooter>
            <Button
              onClick={openTawkChat}
              variant="secondary"
              className="sm:mt-10 rounded-full w-fit"
            >
              Let's Talk
            </Button>
          </CardFooter>
        </Card>
        <Card className="sm:p-5 p-2  rounded-3xl border-4  border-muted ">
          <CardHeader>
            <CardTitle>Tech & Creative Agencies</CardTitle>
          </CardHeader>

          <CardContent className="sm:me-20 md:me-40 text-muted-foreground ">
            We thrive on partnering with both tech and creative agencies.
            Whether you need to develop a new software solution, refine a
            design, or create a full brand, outsource your work to us.and get
            top-notch results.
          </CardContent>

          <CardFooter className="gap-5">
            <Button
              onClick={openTawkChat}
              variant="default"
              className="sm:mt-10 rounded-full w-fit"
            >
              Tech Partner
            </Button>
            <Button
              variant="secondary"
              className="sm:mt-10 rounded-full w-fit"
              onClick={() =>
                toast("Listoti Design", {
                  description: "we are cooking it , stay tuned",
                  action: {
                    label: "Got it",
                    onClick: () => console.log("Undo"),
                  },
                })
              }
            >
              Creative Partner
            </Button>
          </CardFooter>
        </Card>
      </div>

      <div className="container">
        <Separator className="my-4" />
        <h2 className="block md:hidden text-3xl lg:text-4xl  md:text-left container my-10">
          How we work
        </h2>

        <div className="container grid md:grid-cols-3 lg:grid-cols-3">
          <div className="w-full  sm:p-10 p-3">
            <div className="flex h-full items-center space-x-10 text-sm ">
              <h1 className="text-4xl font-bold flex h-full items-center">
                01
              </h1>

              <p className="text-sm text-muted-foreground ">
                Do a quick video call or chat to understand your business needs.
              </p>
            </div>
          </div>

          <div className="w-full sm:p-10 p-3 ">
            <div className="flex h-full items-center space-x-10 text-sm ">
              <h1 className="text-4xl font-bold flex h-full items-center">
                02
              </h1>

              <p className="text-sm text-muted-foreground ">
                Next step is to provide a tailored success roadmap for your
                project.
              </p>
            </div>
          </div>

          <div className="w-full  sm:p-10 p-3">
            <div className="flex h-full items-center space-x-10 text-sm ">
              <h1 className="text-4xl font-bold flex h-full items-center">
                03
              </h1>

              <p className="text-sm text-muted-foreground ">
                Fix a deal and we start working on your project without wasting
                time .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
