import { motion } from "framer-motion";
import Image1 from "../assets/images/icons/Figma_img.png";
import Image2 from "../assets/images/icons/Nextjs_img.png";
import Image3 from "../assets/images/icons/Motion_img.png";
import Image4 from "../assets/images/icons/React_img.png";
import Image5 from "../assets/images/icons/Adobe_img.png";
import Image6 from "../assets/images/icons/node_img.png";
import Image7 from "../assets/images/icons/Vercel_img.png";
import Image8 from "../assets/images/icons/tailwind_img.png";

import {
  TextRevealCard,
  TextRevealCardDescription,
  TextRevealCardTitle,
} from "./ui/text-reveal-card";

export function FloatingTech() {
  const bounceTransition = {
    repeat: Infinity,
    repeatType: "reverse" as "reverse" | "loop" | "mirror",
    ease: "easeOut",
  };

  const boxShadowStyle = "rgba(243, 245, 255, 0.1 ) 0px 12px 89px";
  const boxShadowHover = "rgba(243, 245, 255, 0.4 ) 0px 12px 89px";

  return (
    <section id="tools" className="container h-full pb-24">
      <div className="lg:h-[50rem] h-[40rem] p-12  ">
        <div className="container relative z-30 flex items-center justify-center h-full w-full">
          <h1 className="sm:text-4xl lg:my-32 text-center  md:text-2xl font-regular text-black dark:text-white  ">
            covering wide range of <br />
            <span className="text-4xl md:text-[3rem] font-semibold leading-none">
              latest technologies{" "}
            </span>
          </h1>

          {/* div Web version of Floating images */}
          <div className="hidden sm:block">
            {/* Top Floating Images */}
            <motion.img
              src={Image1}
              alt="Floating Image 1"
              className="absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.8 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "5%",
                left: "15%",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image2}
              alt="Floating Image 2"
              className="hidden md:block absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 1.2 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "10%",
                left: "42%",
                transform: "translateX(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image3}
              alt="Floating Image 3"
              className="absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.9 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "5%",
                right: "15%",
                boxShadow: boxShadowStyle,
              }}
            />
            {/* Bottom Floating Images */}
            <motion.img
              src={Image4}
              alt="Floating Image 4"
              className="absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 1.1 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "5%",
                left: "15%",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image5}
              alt="Floating Image 5"
              className="hidden md:block absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.7 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "10%",
                left: "47%",
                transform: "translateX(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image6}
              alt="Floating Image 6"
              className="absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.8 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "5%",
                right: "15%",
                boxShadow: boxShadowStyle,
              }}
            />
            {/* Side Floating Images */}
            <motion.img
              src={Image7}
              alt="Floating Image 7"
              className="absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 1.0 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "50%",
                left: "-5%",
                transform: "translateY(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image8}
              alt="Floating Image 8"
              className="absolute w-32 h-32 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.9 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "50%",
                right: "-5%",
                transform: "translateY(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
          </div>

          {/* div Mobile versin of Floating images */}
          <div className="block sm:hidden">
            {/* Top Floating Images */}
            <motion.img
              src={Image1}
              alt="Floating Image 1"
              className="absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.8 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "5%",
                left: "40%",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image2}
              alt="Floating Image 2"
              className="hidden md:block absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 1.2 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "10%",
                left: "20%",
                transform: "translateX(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image3}
              alt="Floating Image 3"
              className="absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.9 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "5%",
                right: "0%",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image7}
              alt="Floating Image 7"
              className="absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 1.0 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                top: "5%",
                left: "0%",
                transform: "translateY(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            {/* Bottom Floating Images */}
            <motion.img
              src={Image4}
              alt="Floating Image 4"
              className="absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 1.1 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "5%",
                left: "40%",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image5}
              alt="Floating Image 5"
              className="hidden md:block absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.7 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "10%",
                left: "60%",
                transform: "translateX(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image6}
              alt="Floating Image 6"
              className="absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.8 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "5%",
                right: "0%",
                boxShadow: boxShadowStyle,
              }}
            />
            <motion.img
              src={Image8}
              alt="Floating Image 8"
              className="absolute w-16 h-16 rounded-full border-zinc-800 border-1"
              initial={{ y: 0 }}
              animate={{ y: -20 }}
              transition={{ ...bounceTransition, duration: 0.9 }}
              whileHover={{ boxShadow: boxShadowHover }}
              style={{
                bottom: "5%",
                left: "0%",
                transform: "translateY(-50%)",
                boxShadow: boxShadowStyle,
              }}
            />
            {/* Side Floating Images */}
          </div>
        </div>
      </div>
    </section>
  );
}
