import { Technologies } from "./Technologies";
import { BackgroundBeams } from "./ui/background-beams";
import { HoverBorderGradient } from "./ui/hover-border-gradient";
import { SparklesCore } from "./ui/sparkles";
import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { toast } from "sonner";
import { motion } from "framer-motion";
import PffWalid from "../assets/TeamProfile/Seddikwalid.jpg";
import PffMounir from "../assets/TeamProfile/SeddikMounir.png";
import PffHamia from "../assets/TeamProfile/HamiaWalid.png";
import PffMustafa from "../assets/TeamProfile/ToumiMustafa.png";
import PffDjamal from "../assets/TeamProfile/MehargaDjamal.png";
import Resoiurce1 from "../assets/Resources/Preview-1.png";
import Resoiurce2 from "../assets/Resources/Preview-2.png";
import Resoiurce3 from "../assets/Resources/Preview-3.png";
import Resoiurce4 from "../assets/Resources/Preview-4.png";

import Resoiurce5 from "../assets/Resources/Preview-7.png";
import Resoiurce6 from "../assets/Resources/Preview-6.png";

import { Color } from "three";

export const Newsletters = () => {
  const [state, handleSubmit] = useForm("xzzprrjz");
  const Onsubmit = (value) => {
    handleSubmit(value);
    if (state.succeeded === true) {
      toast("Thanks Boss", {
        description: "Welcome to our Newsletter! ",
        action: {
          label: "Got it",
          onClick: () => console.log("Undo"),
        },
      });
    }
  };

  return (
    <section className="md:container  group/item md:my-24 overflow-hidden  ">
      <div className="  rounded-md relative flex flex-col items-center justify-center w-full  ">
        <div className="  container text-center w-full 	 mx-auto  bg-neutral-900 md:dark:border-4 dark:border-y-4 md:border-muted md:rounded-3xl">
          {/* top particles */}
          <div className="  h-fit w-full bg-neutral-00 flex flex-col items-center justify-center  rounded-md">
            <div className="center w-full  relative">
              {/* Gradients */}
              <div className="hidden md:block absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
              <div className="hidden md:block absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />

              {/* Core component */}
              <SparklesCore
                background="transparent"
                minSize={0.4}
                maxSize={1}
                particleDensity={600}
                className="w-full h-full "
                particleColor="#FFFFFF"
              />

              {/* Radial Gradient to prevent sharp edges */}
              <div className="absolute inset-0 w-full h-full  bg-neutral-900 [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
            </div>
          </div>

          {/* Body if hero */}
          <div className=" relative flex justify-center items-center mb-10">
            <motion.div
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              whileTap={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border  flex-shrink-0 overflow-hidden"
            >
              <img
                src={PffHamia}
                alt="Seddik Mounir"
                width="200"
                height="200"
                className="rounded-xl h-12 w-12 object-cover flex-shrink-0"
              />
            </motion.div>
            <motion.div
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              whileTap={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border  flex-shrink-0 overflow-hidden"
            >
              <img
                src={PffMounir}
                alt="Seddik Mounir"
                width="200"
                height="200"
                className="rounded-xl h-14 w-14 object-cover flex-shrink-0"
              />
            </motion.div>
            <motion.div
              style={{
                zIndex: 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              whileTap={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border  flex-shrink-0 overflow-hidden"
            >
              <img
                src={PffWalid}
                alt="Seddik Walid"
                width="200"
                height="200"
                className="rounded-xl  h-16 w-16 object-cover flex-shrink-0"
              />
            </motion.div>
            <motion.div
              style={{
                zIndex: 1,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              whileTap={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border  flex-shrink-0 overflow-hidden"
            >
              <img
                src={PffMustafa}
                alt="Toumi Abderahmane"
                width="200"
                height="200"
                className="rounded-xl h-14 w-14 object-cover flex-shrink-0"
              />
            </motion.div>
            <motion.div
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              whileTap={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border  flex-shrink-0 overflow-hidden"
            >
              <img
                src={PffDjamal}
                alt=""
                width="200"
                height="200"
                className="rounded-xl h-12 w-12 object-cover flex-shrink-0"
              />
            </motion.div>
          </div>
          {/* Resources Cards */}
          <div className="overflow-hidden">
            <motion.div
              style={{
                rotate: Math.random() * 20 - 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="absolute -bottom-6 left-10 rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border flex-shrink-0 overflow-hidden"
            >
              <img
                src={Resoiurce5}
                alt="Resources"
                width="200"
                height="200"
                className="rounded-xl w-50 h-50 object-cover flex-shrink-0"
              />
            </motion.div>

            <motion.div
              style={{
                rotate: Math.random() * 20 - 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="absolute -bottom-16 left-40 rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border flex-shrink-0 overflow-hidden"
            >
              <img
                src={Resoiurce1}
                alt="Resources"
                width="200"
                height="200"
                className="rounded-xl h-40 w-40 object-cover flex-shrink-0"
              />
            </motion.div>

            <motion.div
              style={{
                rotate: Math.random() * 20 - 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="absolute -bottom-20 left-64 rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border flex-shrink-0 overflow-hidden"
            >
              <img
                src={Resoiurce2}
                alt="Resources"
                width="200"
                height="200"
                className="rounded-xl h-32 w-32 object-cover flex-shrink-0"
              />
            </motion.div>

            <motion.div
              style={{
                rotate: Math.random() * 20 - 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="absolute -bottom-6 right-10 rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border flex-shrink-0 overflow-hidden"
            >
              <img
                src={Resoiurce3}
                alt="Resources"
                width="200"
                height="200"
                className="rounded-xl h-50 w-50 object-cover flex-shrink-0"
              />
            </motion.div>

            <motion.div
              style={{
                rotate: Math.random() * 20 - 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="absolute -bottom-16 right-40 rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border flex-shrink-0 overflow-hidden"
            >
              <img
                src={Resoiurce4}
                alt="Resources"
                width="200"
                height="200"
                className="rounded-xl h-40 w-40 object-cover flex-shrink-0"
              />
            </motion.div>

            <motion.div
              style={{
                rotate: Math.random() * 20 - 10,
              }}
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="absolute -bottom-20 right-64 rounded-xl -mr-4 mt-4 p-1 bg-neutral-800 border-neutral-700 border flex-shrink-0 overflow-hidden"
            >
              <img
                src={Resoiurce6}
                alt="Resources"
                width="200"
                height="200"
                className="rounded-xl h-32 w-32 object-cover flex-shrink-0"
              />
            </motion.div>
          </div>

          <h1 className="mt-5  z-10 text-3xl	sm:md:text-4xl md:text-5xl w-full h-contetnt bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-400  text-center font-sans font-semibold">
            Join our community and
            <br />
            claim free Products
            <br />
          </h1>
          <p className="text-neutral-500 max-w-lg mx-auto my-8 text-md text-center text-base  ">
            No Spam. Only valuable content and updates of our products. Join
            other creators in our community
          </p>

          <div className="sm:mx-16">
            <form
              onSubmit={Onsubmit}
              className="bg-zinc-800 mx-auto p-4 rounded-lg h-fit w-full  sm:w-fit m-16 mb-40 gap-4 flex flex-col sm:flex-row justify-center items-center text-center"
            >
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Email address..."
                className=" rounded-lg p-3  sm:mb-0  text-black dark:text-white w-full dark:bg-zinc-700"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <button
                type="submit"
                disabled={state.submitting}
                className="md:rounded-lg rounded-full  w-1/3"
              >
                <HoverBorderGradient
                  containerClassName="rounded-full w-full"
                  as="button"
                  className="dark:bg-black bg-black text-white dark:text-white  flex items-center space-x-2  "
                >
                  <span>Submit</span>
                </HoverBorderGradient>{" "}
              </button>
            </form>
          </div>
        </div>

        {/* backgroundlines 
        <BackgroundBeams />
      */}
      </div>
    </section>
  );
};
