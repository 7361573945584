import { InstagramLogoIcon } from "@radix-ui/react-icons";
import { LogoIcon } from "./Icons";
import { Badge } from "./ui/badge";
import { motion } from "framer-motion";
import PffHamia from "../assets/LogoIcon.png";

export const Footer = () => {
  return (
    <footer id="footer">
      <hr className="w-11/12 mx-auto" />

      <section className="container py-20 grid sm:grid-cols-2   gap-x-12 gap-y-8">
        <div className="sm:w-2/3 ">
          <a href="/" className="font-bold text-xl flex items-center gap-4">
            <motion.div
              whileHover={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              whileTap={{
                scale: 1.1,
                rotate: 0,
                zIndex: 100,
              }}
              className="rounded-xl  p-1 bg-neutral-800 border-neutral-700 border  flex-shrink-0 overflow-hidden"
            >
              <img
                src={PffHamia}
                width="200"
                height="200"
                className="rounded-xl h-12 w-12 object-cover flex-shrink-0"
              />
            </motion.div>
            <LogoIcon />
          </a>
          <p className="py-5 text-sm  ">
            Listoit was created by{"  "}
            <a
              href="https://www.linkedin.com/in/seddikwalid/"
              className="font-bold "
              target="_blank"
            >
              S.walid
            </a>{" "}
            and other members including Designers and software engineers only
            for one reason to help startups.
          </p>
        </div>
        <div className="sm:w-4/5 flex flex-row sm:justify-between   gap-x-12 gap-y-8">
          <div className="">
            <h3 className="font-bold text-lg">Follow us</h3>
            <div>
              <a
                rel="noreferrer noopener"
                href="https://www.linkedin.com/company/listoit/"
                className="opacity-60 hover:opacity-100"
                target="_blank"
              >
                Linkedin
              </a>
            </div>

            <div>
              <a
                rel="noreferrer noopener"
                href="https://x.com/ListoitAgency"
                className="opacity-60 hover:opacity-100"
                target="_blank"
              >
                Twitter
              </a>
            </div>
            <div>
              <a
                rel="noreferrer noopener"
                href="https://www.instagram.com/listoit.agency/"
                className="opacity-60 hover:opacity-100"
                target="_blank"
              >
                Instgram
              </a>
            </div>
          </div>
          <div className="">
            <h3 className="font-bold text-lg">Products</h3>
            <div className="flex flex-row">
              <a
                rel="noreferrer noopener"
                href="#"
                className="opacity-60 hover:opacity-100"
              >
                Sahlla{" "}
                <Badge variant="secondary" className="text-green-500 pl-4">
                  <a href="https://sahlla.listoit.com/" target="blank">
                    Discover
                  </a>
                </Badge>
              </a>
            </div>

            <div>
              <a
                rel="noreferrer noopener"
                href="#"
                className="opacity-60 hover:opacity-100"
              >
                Oceantrip{" "}
                <Badge variant="secondary" className="text-gray-500 pl-4">
                  Soon
                </Badge>
              </a>
            </div>

            <div>
              <a
                rel="noreferrer noopener"
                href="#"
                className="opacity-60 hover:opacity-100"
              >
                Dealme{" "}
                <Badge variant="secondary" className="text-gray-500 pl-4">
                  Soon
                </Badge>
              </a>
            </div>
          </div>
        </div>
      </section>
      <hr className="w-11/12 mx-auto" />

      <section className="container py-8 text-center">
        <h3>&copy; Listoit 2024. All rights reserved. </h3>
      </section>
    </footer>
  );
};
