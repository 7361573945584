import { Button } from "./ui/button";

("use client");
import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalTrigger,
} from "@/components/ui/animated-modal";
import Image1 from "../assets/Seddikwalid.jpg";

import { motion } from "framer-motion";
import { FAQ } from "./FAQ";
export const Cta = () => {
  return (
    <section
      id="cta"
      className="md:container grid lg:grid-cols-2 lg:rounded-3xl bg-muted/50  gap-y-16 md:p-16 p-8 mt-24 sm:my-24"
    >
      <div className="relative bg-muted rounded-3xl md:p-16 p-8  justify-center lg:h-1/2 md:h-fit h-3/4   ">
        <div className="lg:col-start-1 lg:w-full md:w-2/3 w-full ">
          <h1 className="sm:text-4xl  md:text-2xl font-regular text-black dark:text-white sm:text-left text-center   ">
            We are available for a
            <br />
            <span className="text-4xl md:text-[3rem] font-semibold mt-1 leading-none">
              Quick Video Call
            </span>
          </h1>
          <p className="sm:text-left text-center text-muted-foreground text-md mt-4 mb-8 lg:mb-0">
            Book a call now and let's talk about your product or startup goals .
          </p>
        </div>

        <div className="flex w-full justify-center">
          <Button className=" md:absolute mr-0  right-10 -bottom-16 w-32 h-32 bg-black dark:bg-white dark:text-black text-white rounded-full group/modal-btn">
            <span className=" mt-4 group-hover/modal-btn:-translate-y-2 text-center transition duration-500">
              <a
                rel="noreferrer noopener"
                href="https://cal.com/listoit/intro-call-startup"
                target="_blank"
              >
                Book Call
              </a>
            </span>
          </Button>
          {/*   <Modal>
            <ModalTrigger className=" w-24 h-24 bg-black dark:bg-white dark:text-black text-white flex justify-center group/modal-btn rounded-full">
              <span className=" mt-4 group-hover/modal-btn:translate-x-40 i text-center transition duration-500">
                Book Call
              </span>

              <div className="-translate-x-40 group-hover/modal-btn:translate-x-0 flex items-center justify-center absolute inset-0 transition duration-500 text-white z-20">
                🔳
              </div>
            </ModalTrigger>
            <ModalBody>
              <ModalContent>
                <h4 className="text-lg md:text-2xl text-neutral-600 dark:text-neutral-100 font-bold text-center mb-8">
                  Book a video intro call .
                </h4>
                <div className="flex justify-center items-center">
                  <motion.div
                    style={{
                      rotate: Math.random() * 20 - 10,
                    }}
                    whileHover={{
                      scale: 1.1,
                      rotate: 0,
                      zIndex: 100,
                    }}
                    whileTap={{
                      scale: 1.1,
                      rotate: 0,
                      zIndex: 100,
                    }}
                    className="rounded-xl -mr-4 mt-4 p-1 bg-white dark:bg-neutral-800 dark:border-neutral-700 border border-neutral-100 flex-shrink-0 overflow-hidden"
                  >
                    <img
                      src={Image1}
                      alt="Seddik Walid"
                      width="200"
                      height="200"
                      className="rounded-xl h-20 w-20 md:h-20 md:w-20 object-cover flex-shrink-0"
                    />
                  </motion.div>
                  <motion.div
                    style={{
                      rotate: Math.random() * 20 - 10,
                    }}
                    whileHover={{
                      scale: 1.1,
                      rotate: 0,
                      zIndex: 100,
                    }}
                    whileTap={{
                      scale: 1.1,
                      rotate: 0,
                      zIndex: 100,
                    }}
                    className="rounded-xl -mr-4 mt-4 p-1 bg-white dark:bg-neutral-800 dark:border-neutral-700 border border-neutral-100 flex-shrink-0 overflow-hidden"
                  >
                    <img
                      src={Image1}
                      alt="Seddik Walid"
                      width="200"
                      height="200"
                      className="rounded-xl h-20 w-20 md:h-20 md:w-20 object-cover flex-shrink-0"
                    />
                  </motion.div>
                  <motion.div
                    style={{
                      rotate: Math.random() * 20 - 10,
                    }}
                    whileHover={{
                      scale: 1.1,
                      rotate: 0,
                      zIndex: 100,
                    }}
                    whileTap={{
                      scale: 1.1,
                      rotate: 0,
                      zIndex: 100,
                    }}
                    className="rounded-xl -mr-4 mt-4 p-1 bg-white dark:bg-neutral-800 dark:border-neutral-700 border border-neutral-100 flex-shrink-0 overflow-hidden"
                  >
                    <img
                      src={Image1}
                      alt="Seddik Walid"
                      width="200"
                      height="200"
                      className="rounded-xl h-20 w-20 md:h-20 md:w-20 object-cover flex-shrink-0"
                    />
                  </motion.div>
                </div>
                <div className="py-10 flex flex-wrap gap-x-4 gap-y-6 items-start justify-start max-w-sm mx-auto">
                  <div className="flex  items-center justify-center">
                    <PlaneIcon className="mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4" />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                      Experts in Design, Development, Prototyping
                    </span>
                  </div>

                  <div className="flex items-center justify-center">
                    <VacationIcon className="mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4" />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                      3 Startup lauched
                    </span>
                  </div>
                  <div className="flex  items-center justify-center">
                    <FoodIcon className="mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4" />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                      Good Mood everyday
                    </span>
                  </div>
                  <div className="flex items-center justify-center">
                    <MicIcon className="mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4" />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                      Open Mic
                    </span>
                  </div>
                  <div className="flex items-center justify-center">
                    <ParachuteIcon className="mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4" />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                      Available for new Project
                    </span>
                  </div>
                </div>
              </ModalContent>
              <ModalFooter className="gap-4">
                <button className="px-2 py-1 bg-gray-200 text-black dark:bg-black dark:border-black dark:text-white border border-gray-300 rounded-md text-sm w-28">
                  Cancel
                </button>
                <Button className="bg-black text-white dark:bg-white dark:text-black text-sm px-2 py-1 rounded-md border border-black w-28">
                  <a
                    rel="noreferrer noopener"
                    href="https://cal.com/listoit/intro-call-startup"
                    target="_blank"
                  >
                    Book Now
                  </a>
                </Button>
              </ModalFooter>
            </ModalBody>
          </Modal>
          */}
        </div>
      </div>
      <div className="lg:px-16">
        <FAQ />
      </div>
    </section>
  );
};

const PlaneIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h4l-2 -7h3z" />
    </svg>
  );
};
