import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "./App.css";
import { Cta } from "./components/Cta";
import { Footer } from "./components/Footer";
import { FloatingTech } from "./components/FloatingTech";
import { Newsletters } from "./components/Hero";
import { HeroScrollDemo } from "./components/HeroScrollDemo";
import { SpotlightHero } from "./components/HeroSpotlight";
import { Intro } from "./components/Intro";
import { NavbarMenu } from "./components/NavbarMenu";
import { OurTarget } from "./components/OurTarget";
import { RecentTempalte } from "./components/RecentTemplate";
import { ScrollToTop } from "./components/ScrollToTop";
import { Splashscreen } from "./components/splashscreen";
import { Toaster } from "./components/ui/sonner";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

function SectionWrapper({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust this threshold as needed
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: "easeOut" },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div ref={ref} initial={{ opacity: 0, y: 50 }} animate={controls}>
      {children}
    </motion.div>
  );
}

function App() {
  return (
    <>
      <Splashscreen />
      <NavbarMenu />
      <SpotlightHero />
      <SectionWrapper>
        <Intro />
      </SectionWrapper>
      <SectionWrapper>
        <OurTarget />
      </SectionWrapper>
      <SectionWrapper>
        <RecentTempalte />
      </SectionWrapper>
      <SectionWrapper>
        <FloatingTech />
      </SectionWrapper>
      <SectionWrapper>
        <HeroScrollDemo />
      </SectionWrapper>
      <SectionWrapper>
        <Cta />
      </SectionWrapper>
      <SectionWrapper>
        <Newsletters />
      </SectionWrapper>
      <Footer />
      <ScrollToTop />
      <Toaster />
      <SpeedInsights />
      <Analytics />
    </>
  );
}

export default App;
